import { useState, useEffect, useRef } from "react";

const useOverflowTooltip = () => {
    const ref = useRef(null);
    const [isOverflowed, setIsOverflowed] = useState(false);

    useEffect(() => {
        const el = ref.current;

        if (!el) return;

        const checkOverflow = () => {
            setIsOverflowed(el.scrollWidth > el.clientWidth);
        };

        const resizeObserver = new ResizeObserver(checkOverflow);

        resizeObserver.observe(el);

        checkOverflow();

        return () => resizeObserver.disconnect();
    }, [ref.current]);

    return [ref, isOverflowed];
};

export default useOverflowTooltip;
