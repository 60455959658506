import useOverflowTooltip from "../../hooks/useOverflowTooltip";
import { Tooltip as ReactTooltip } from "react-tooltip";

const CellContentWithTooltip = ({ content, id }) => {
    const [ref, isOverflowed] = useOverflowTooltip();

    return (
        <div ref={ref} className="cell-content">
            {content}
            {isOverflowed && typeof content === "string" && (
                <ReactTooltip
                    id={id}
                    place="top"
                    delayShow={500}
                    type="light"
                    effect="solid"
                    positionStrategy="fixed"
                />
            )}
        </div>
    );
};

export default CellContentWithTooltip;
