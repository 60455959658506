import { DATE_OPTIONS } from "../constants/report";

const countryCodeToFlagEmoji = (countryCode) => {
    if (!countryCode) return "";
    if (countryCode.includes(".")) return countryCode;
    return countryCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(127397 + char.charCodeAt()));
};

const getDateRange = (option) => {
    const now = new Date();
    const startOfDay = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));

    let time_start, time_end;

    switch (option) {
        case DATE_OPTIONS.TODAY:
            time_start = startOfDay.toISOString();
            break;

        case DATE_OPTIONS.YESTERDAY:
            time_start = new Date(startOfDay - 86400000).toISOString();
            time_end = startOfDay.toISOString();
            break;

        case DATE_OPTIONS.THIS_WEEK:
            const startOfWeek = new Date(startOfDay);
            const day = startOfWeek.getDay();
            const diffToMonday = day === 0 ? -6 : 1 - day;
            startOfWeek.setDate(startOfWeek.getDate() + diffToMonday);
            time_start = startOfWeek.toISOString();
            break;

        case DATE_OPTIONS.LAST_7_DAYS:
            time_start = new Date(startOfDay - 6 * 86400000).toISOString();
            time_end = now.toISOString();
            break;

        case DATE_OPTIONS.THIS_MONTH:
            const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
            time_start = startOfMonth.toISOString();
            break;

        case DATE_OPTIONS.PREVIOUS_MONTH:
            const startOfPrevMonth = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() - 1, 1));
            const endOfPrevMonth = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 1));

            time_start = startOfPrevMonth.toISOString();
            time_end = endOfPrevMonth.toISOString();
            break;

        case DATE_OPTIONS.LAST_30_DAYS:
            time_start = new Date(startOfDay - 29 * 86400000).toISOString();
            break;

        case DATE_OPTIONS.THIS_YEAR:
            const startOfYear = new Date(Date.UTC(now.getUTCFullYear(), 0, 1));
            time_start = startOfYear.toISOString();
            break;

        default:
            throw new Error("Invalid date range option");
    }

    return { time_start, time_end };
};

const urlToObject = (url) => {
    const searchParams = new URLSearchParams(url);
    const obj = {};
    searchParams.forEach((value, key) => {
        obj[key] = value;
    });
    return obj;
};

const formateOptionsForFetchData = ({
    columns,
    selectedFilters,

    selectedGroups,

    sort_by,
    sort_order,
    time_start,
    time_end,
    timezone,
    dateRangeValue,
    page,

    user_id,
}) => {
    const options = {
        columns: [],
        timezone,
        page,
        dateRangeValue,
    };

    columns.forEach((col) => {
        if (!col.isHidden) {
            options.columns.push(col.accessor);
        }
    });

    if (+user_id) {
        options.user_id = user_id;
    }

    if (selectedFilters?.length) {
        options.filters = [...selectedFilters];
    }

    if (selectedGroups?.length) {
        options.group_by = [...selectedGroups];
    }

    if (sort_by) {
        options.sort_by = sort_by;
        options.sort_order = sort_order;
    }
    if (dateRangeValue === DATE_OPTIONS.ALL_TIME) return options;

    if (dateRangeValue !== DATE_OPTIONS.RANGE_OF_DAYS && dateRangeValue !== DATE_OPTIONS.TIME_RANGE) {
        const dateRange = getDateRange(dateRangeValue);
        options.time_start = dateRange.time_start;
        if (dateRange.time_end) {
            options.time_end = dateRange.time_end;
        }
    } else if (time_start && time_end) {
        const offsetMinutes = new Date().getTimezoneOffset(); // смещение в минутах
        const offsetMs = offsetMinutes * 60 * 1000;

        const adjustedStart = new Date(new Date(time_start).getTime() - offsetMs);
        const adjustedEnd = new Date(new Date(time_end).getTime() - offsetMs);

        options.time_start = adjustedStart.toISOString();
        options.time_end = adjustedEnd.toISOString();
    } else {
        const dateRange = getDateRange(DATE_OPTIONS.TODAY);
        options.time_start = dateRange.time_start;
    }

    return options;
};

const formateOptions = ({ columns, timezones, filters, filter_operators, group_by }, customOptions, columnWidths) => {
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const opts = {
        columns: columns.map((column) => ({...column, Header: column.label, accessor: column.field, isDefault: Boolean(column.default) })),
        selectedFilters: [],

        selectedGroups: [],
        initial_filters: filters,
        initial_groups: group_by,
        filter_operators,
        sort_by: "",
        sort_order: "",
        timezone: timezones.find(({ timezone }) => timezone === currentTimezone)?.timezone || timezones[0],
        timezones,
        dateRangeValue: DATE_OPTIONS.TODAY,
        time_start: null,
        time_end: null,
        page: 1,
        user_id: 0,
        ...customOptions,
    };

    if (typeof opts.columns === "string") {
        const order = customOptions?.columns?.split(",");

        // Сортируем `columns` по `order`
        const sortedColumns = columns.sort((a, b) => {
            const indexA = order.indexOf(a.field);
            const indexB = order.indexOf(b.field);

            if (indexA === -1 && indexB === -1) return 0; // Оба не в order → оставляем их как есть
            if (indexA === -1) return 1; // a не в order → отправляем вниз
            if (indexB === -1) return -1; // b не в order → отправляем вниз

            return indexA - indexB; // Обычная сортировка по порядку в order
        });

        opts.columns = sortedColumns.map((column) => ({
            ...column,
            Header: column.label,
            accessor: column.field,
            isHidden: !order.includes(column.field),
            isDefault: Boolean(column.default),
        }));
    } else {
        // display fields by default
        opts.columns = opts.columns.map((col) => ({...col, isHidden: !Boolean(col.isDefault)}))
    }

    if (columnWidths && Object.keys(columnWidths).length) {
        opts.columns = opts.columns.map((col) => {
            if (columnWidths[col.accessor]) {
                return { ...col, width: columnWidths[col.accessor] };
            }

            return col;
        });
    }
    if (opts.filters) {
        opts.selectedFilters = JSON.parse(opts.filters);
        delete opts.filters;
    }

    if (opts.group_by) {
        opts.selectedGroups = opts.group_by.split(",");
        delete opts.group_by;
    }

    return opts;
};

const adjustColumnsToData = (data, columns) => {
    const dataFields = Object.keys(data[0]);

    const updatedColumns = columns.map((col) => ({
        ...col,
        isHidden: !dataFields.includes(col.accessor),
    }));

    dataFields.forEach((field) => {
        const columnExists = updatedColumns.some(({ accessor }) => accessor === field);
        if (!columnExists) {
            updatedColumns.push({ accessor: field, Header: field, isHidden: false });
        }
    });

    return updatedColumns;
};


export { getDateRange, formateOptions, formateOptionsForFetchData, urlToObject, adjustColumnsToData, countryCodeToFlagEmoji };
