import { useState } from "react";
import Button from "../../components/UI/Button";
import ModalWindow from "../../widgets/ModalWindow";
import Select from "react-select";
import CloseIcon from "../../components/icons/CloseIcon";
import CreatableSelect from "react-select/creatable";

const getFilters = (selectedFilters, initialFilters, filterOperators) => {
    const filters = [];
    selectedFilters.forEach((filter, index) => {
        const type = initialFilters.find(({ field }) => field === filter.field)?.type;
        const operator = filterOperators[type]?.find(({ operator }) => operator === filter.operator);
        const id = Date.now() + index;
        filters.push({ ...filter, ...operator, type, id });
    });

    if (!filters.length) {
        // one empty filter by default
        filters.push({ id: Date.now() });
    }

    return filters;
};

const Filters = ({ selectedFilters, initialFilters, filterOperators, updateOptions }) => {
    const [filters, setFilters] = useState(getFilters(selectedFilters, initialFilters, filterOperators));
    const [isOpenModal, setIsOpenModal] = useState(false);

    const closeModal = () => {
        setIsOpenModal(false);
        setFilters(getFilters(selectedFilters, initialFilters, filterOperators));
    };
    const openModal = () => setIsOpenModal(true);

    const handlerApplyBtn = () => {
        const newSelectedFilters = [];

        filters.forEach((filter) => {
            if (Object.keys(filter).length === 1) return; // filter is empty

            if(!filter.field || !filter.value) return;

            const newFilter = {
                field: filter.field,
                operator: filter.operator,
                value: filter.value,
            };

            newSelectedFilters.push(newFilter);
        });

        updateOptions({
            selectedFilters: newSelectedFilters,
        });
        setIsOpenModal(false);
    };

    const addFilter = () => {
        setFilters((prev) => [...prev, { id: Date.now() }]);
    };

    const removeFilter = (id) => {
        if (filters.length !== 1) {
            setFilters((prev) => prev.filter((item) => item.id !== id));
            return;
        }
        // clean the last filter
        setFilters([{ id }]);
    };

    const updateFilter = (newValue, id) => {
        const filterCopy = [...filters];
        const index = filterCopy.findIndex((item) => item.id === id);
        filterCopy[index] = { id, ...newValue };
        delete filterCopy[index].label;
        setFilters(filterCopy);
    };

    const handlerOperatorChange = (newOperator, id) => {
        const filter = filters.find((filter) => filter.id === id);
        delete filter.argType;

        if (newOperator.argType && !Array.isArray(filter.value)) delete filter.value;

        if (!newOperator.argType && Array.isArray(filter.value)) delete filter.value;
        
        updateFilter({ ...filter, ...newOperator }, id);
    };

    const handlerValueChange = (newValue, id) => {
        const filter = filters.find((filter) => filter.id === id);
        updateFilter({ ...filter, ...newValue }, id);
    };

    const findOperator = (operator, type) => filterOperators[type]?.find((opt) => opt.operator === operator) || "";

    const renderOperator = ({ id, type, operator }) => {
        if (filterOperators[type]) {
            return (
                <Select
                    isSearchable={false}
                    className="select small"
                    value={findOperator(operator, type)}
                    getOptionValue={(e) => e.operator}
                    options={filterOperators[type]}
                    onChange={(newValue) => handlerOperatorChange(newValue, id)}
                />
            );
        }

        return <Select className="select small" isDisabled />;
    };

    const renderValueField = ({ argType, value, id, type, operator }) => {
        if (argType === "list") {
            const val = Array.isArray(value) ? value.map((val) => ({ label: val, value: val })) : "";
            return (
                <CreatableSelect
                    isClearable
                    className="select small"
                    value={val}
                    formatCreateLabel={(input) => `Добавить "${input}"`}
                    onChange={(newValue) => handlerValueChange({ value: newValue.map((item) => item.value) }, id)}
                    options={[]}
                    isMulti
                />
            );
        }

        if (filterOperators[type]) {
            return (
                <input
                    type="text"
                    className="text-input"
                    disabled={!Boolean(operator)}
                    value={value || ""}
                    onChange={(e) => handlerValueChange({ value: e.target.value }, id)}
                />
            );
        }

        return (
            <input
                type="text"
                className="text-input"
                disabled
            />
        );
    };

    return (
        <div>
            <Button
                title={`Фильтры ${selectedFilters.length ? selectedFilters.length : ""}`}
                withoutRounded
                size="small"
                onClick={openModal}
            />
            <ModalWindow
                className="report-filters-modal"
                isVisible={isOpenModal}
                onClose={closeModal}
                anchorContainerID="report-page"
                closeButton
            >
                <h1>Фильтры</h1>
                <div className="filter-list">
                    {filters.map((item) => (
                        <div className="filter-item" key={item.id}>
                            <div
                                className={`delete-btn ${
                                    filters.length === 1 && Object.keys(item).length === 1 ? "hidden" : ""
                                }`}
                                onClick={() => removeFilter(item.id)}
                            >
                                <CloseIcon />
                            </div>
                            <Select
                                className="select small"
                                value={initialFilters.find((opt) => opt.field === item.field) || ""}
                                getOptionValue={(e) => e.field}
                                options={initialFilters}
                                onChange={(newValue) => updateFilter(newValue, item.id)}
                            />
                            {renderOperator(item)}
                            {renderValueField(item)}
                        </div>
                    ))}
                    <Button title="Добавить" withoutRounded variant="warning" onClick={addFilter} size="small" />
                </div>

                <div className="buttons-block">
                    <Button onClick={closeModal} title="Отмена" withoutRounded variant="error" />
                    <Button withoutRounded title="Применить" onClick={handlerApplyBtn} />
                </div>
            </ModalWindow>
        </div>
    );
};

export default Filters;
