import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTable, useSortBy, useBlockLayout, useResizeColumns } from "react-table";
import ArrowLeft from "../../components/icons/ArrowLeft";
import { DEFAULT_COLUMN_WIDTH } from "../../constants/report";
import { adjustColumnsToData } from "../../util/report";
import CellContentWithTooltip from "./CellContentWithTooltip";
import ReactCountryFlag from "react-country-flag";

function Table({ data, columns, sortBy, order, updateOptions, isLoading, saveColumnWidths }) {
    const [columnWidths, setColumnWidths] = useState({});
    const [isSaveColumnWidths, setIsSaveColumnWidths] = useState(false);
    const [isScrollableRight, setIsScrollableRight] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const tableWrapperRef = useRef(null);
    const tableBodyRef = useRef(null);

    const { defaultColumnStyle, visibleColumns } = useMemo(() => {
        const style = {
            minWidth: 30,
        };

        let updatedColumns = [...columns];

        if (data?.length) {
            updatedColumns = adjustColumnsToData(data, columns)
        }

        const widths = {};
        updatedColumns.forEach((col) => {
            if (col.width) {
                widths[col.accessor] = col.width;
            }
        });

        setColumnWidths(widths);

        const visibleColumns = updatedColumns.filter((col) => !col.isHidden);

        const navbar = document.querySelector(".navbar");
        const navbarWidth = navbar.clientWidth;
        const columnWidth = navbarWidth / visibleColumns.length;

        style.width = columnWidth < DEFAULT_COLUMN_WIDTH ? DEFAULT_COLUMN_WIDTH : columnWidth;
        return { defaultColumnStyle: style, visibleColumns };
    }, [columns, window.clientWidth, data]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns: visibleColumns,
            data,
            defaultColumn: defaultColumnStyle,
        },
        useBlockLayout,
        useResizeColumns,
        useSortBy // Добавляем сортировку
    );

    const handlerSortingClick = (name) => {
        updateOptions({ sort_by: name, sort_order: order === "asc" ? "desc" : "asc" });
    };

    const handleColumnResize = (column) => {
        return (e) => {
            e.stopPropagation(); // Не даём ресайзу триггерить сортировку

            const newWidth = column.totalWidth; // Текущая ширина после ресайза
            const accessor = column.id; // Получаем accessor колонки

            // Обновляем state и localStorage
            setColumnWidths((prevWidths) => {
                const updatedWidths = { ...prevWidths, [accessor]: newWidth };
                return updatedWidths;
            });
        };
    };

    const onMouseUpResize = () => {
        setIsSaveColumnWidths(true);
    };

    useEffect(() => {
        if (isSaveColumnWidths) {
            saveColumnWidths(columnWidths);
            setIsSaveColumnWidths(false);
        }
    }, [columnWidths, isSaveColumnWidths]);

    useEffect(() => {
        const checkScroll = () => {
            if (!tableWrapperRef.current) return;
            const { scrollLeft, scrollWidth, clientWidth } = tableWrapperRef.current;
            setIsScrollableRight(scrollWidth > clientWidth && scrollLeft + clientWidth < scrollWidth);
        };

        const updateReportPageWidth = () => {
            const navbar = document.querySelector(".navbar");
            const reportPage = document.querySelector("#report-page");
    
            if (navbar && reportPage) {
                const navbarWidth = navbar.offsetWidth;
                reportPage.style.width = `${navbarWidth}px`;
            }
        };

        const updateReportTableHeight = () => {
            const wrapper = tableWrapperRef.current;
            if (wrapper) {
                const reportPage = document.querySelector('.report-page');
                const footer = document.querySelector('.footer');
                const tableWrapper = document.querySelector('.react-table-wrapper');
        
                let totalHeight = 0;
        
                if (reportPage) {
                    const reportPageStyles = window.getComputedStyle(reportPage);
                    totalHeight += parseFloat(reportPageStyles.paddingBottom);
                }
        
                if (footer) {
                    totalHeight += footer.getBoundingClientRect().height;
                }
        
                if (tableWrapper) {
                    const tableWrapperStyles = window.getComputedStyle(tableWrapper);
                    totalHeight += parseFloat(tableWrapperStyles.paddingBottom);
                }
        
                const offsetTop = wrapper.getBoundingClientRect().top;
                const viewportHeight = window.innerHeight;
                const desiredHeight = viewportHeight - offsetTop - totalHeight;
        
                wrapper.style.maxHeight = `${desiredHeight}px`;
            }
        };

        const handleResize = () => {
            checkScroll();
            updateReportPageWidth();
            updateReportTableHeight()
        };

        const handleClickOutside = (event) => {
            if (tableBodyRef.current && !tableBodyRef.current.contains(event.target)) {
                setSelectedRows([]);
            }
        };

        handleResize();

        tableWrapperRef.current?.addEventListener("scroll", checkScroll);
        window.addEventListener("resize", handleResize);
        document.addEventListener("mousedown", handleClickOutside);
    
        return () => {
            tableWrapperRef.current?.removeEventListener("scroll", checkScroll);
            window.removeEventListener("resize", handleResize);
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    const handleRowClick = (rowIndex) => {
        setSelectedRows((prev) => prev.includes(rowIndex) ? prev.filter((i) => i !== rowIndex) : [...prev, rowIndex]);
    };

    return (
        <div className="react-table-wrapper" >
            <div ref={tableWrapperRef} className="perfect-scrollbar" >
                <div {...getTableProps()} className={`table ${isLoading ? "loading-table" : ""}`}>
                    <div className="head-wrapper">
                        {headerGroups.map((headerGroup) => {
                            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
                            return (
                                <div key={key} {...headerGroupProps} className="tr head-tr" >
                                    {headerGroup.headers.map((column, columnIndex) => {
                                        const { key, ...headerProps } = column.getHeaderProps(
                                            column.getSortByToggleProps()
                                        );
                                        const cellId = 'th' + String(columnIndex);
                                        const isSorted = column.isSorted;
                                        const sortClass = isSorted
                                            ? column.isSortedDesc
                                                ? "sorted-desc"
                                                : "sorted-asc"
                                            : "";

                                        return (
                                            <div
                                                key={key}
                                                {...headerProps}
                                                className={`th ${sortClass}`}
                                                onClick={() => handlerSortingClick(column.id)}
                                                data-tooltip-content={column.Header}
                                                data-tooltip-id={cellId}
                                            >
                                                <div className="th-body">
                                                    <CellContentWithTooltip content={column.Header} id={cellId}/>
                                                    {sortBy === column.id && (
                                                        <span className={`sort-icon ${order}`}>
                                                            <ArrowLeft />
                                                        </span>
                                                    )}
                                                </div>
                                                <div
                                                    {...column.getResizerProps()}
                                                    onClick={handleColumnResize(column)}
                                                    onMouseUp={onMouseUpResize}
                                                    className={`resizer ${column.isResizing ? "isResizing" : ""}`}
                                                >
                                                    <div />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>

                    <div ref={tableBodyRef} {...getTableBodyProps()}>
                        {rows.map((row, rowIndex) => {
                            prepareRow(row);
                            const { key, ...rowProps } = row.getRowProps();

                            return (
                                <div key={key} {...rowProps} className={`tr body-tr ${selectedRows.includes(key) ? 'active' : ''}`} onClick={() => handleRowClick(key)}>
                                    {row.cells.map((cell, cellIndex) => {
                                        const { key, ...cellProps } = cell.getCellProps();
                                        let value = cell.render("Cell"); 
                                        const cellId = String(rowIndex) + String(cellIndex)

                                        if (cell.column.id === "country_flag" && !cell.value?.includes(".")) {
                                            value = (
                                                <ReactCountryFlag
                                                    countryCode={cell.value}
                                                    title={cell.value}
                                                    svg
                                                    style={{
                                                        height: "12px",
                                                        width: "auto",
                                                        verticalAlign: "",
                                                        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.3)",
                                                        margin: "1px",
                                                    }}
                                                    
                                                />
                                            );
                                            cell.value = value;
                                        }

                                      
                                        return (
                                            <div key={key} {...cellProps} className="td" data-tooltip-content={cell.value} data-tooltip-id={cellId}>
                                                <CellContentWithTooltip content={cell.value} id={cellId}/>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="blackout" style={{ opacity: isScrollableRight ? 1 : 0 }} />
        </div>
    );
}

export default Table;
