import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import EditIcon from "../../../components/icons/EditIcon";
import StatsIcon from "../../../components/icons/StatsIcon";
import DuplicateIcon from "../../../components/icons/DuplicateIcon";
import CopyToClipboard from "react-copy-to-clipboard";
import CopyIcon from "../../../components/icons/CopyIcon";

const PWAItem = ({ pwaData, showDuplicateModal, isShowDuplicateBtn }) => {
    return (
        <div className="pwa-item">
            <div className="pwa-item-content">
                {/* <div className="pwa-id">#{pwaData.id}</div> */}
                <div className="pwa-icon">
                    <div
                        style={{
                            backgroundImage: `url("/images/${pwaData.icon}")`,
                        }}
                    ></div>
                </div>
                <div className="pwa-name-languages">
                    <span className="pwa-name">{pwaData.name}</span>
                    <div className="pwa-languages">
                        {pwaData.langs &&
                            pwaData.langs.split(",").map((lng) => (
                                <div key={lng} className="tinyItem">
                                    {lng}
                                </div>
                            ))}
                    </div>
                    <div className="id-block">
                        <CopyToClipboard text={pwaData.id}>
                            <button
                                type="button"
                                data-tooltip-content="Скопировать"
                                data-tooltip-id={"tooltip" + pwaData.id}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <CopyIcon />
                            </button>
                        </CopyToClipboard>
                        <span>#{pwaData.id}</span>
                    </div>
                </div>

                <div className="pwa-actions">
                    <Link
                        to={`/editpwa/?id=${pwaData.id}`}
                        data-tooltip-delay-show={100}
                        data-tooltip-content="Редактировать"
                        data-tooltip-id={"tooltipEdit" + pwaData.id}
                    >
                        <EditIcon />
                    </Link>
                    {isShowDuplicateBtn && (
                        <button
                            type="button"
                            data-tooltip-content="Дублировать"
                            data-tooltip-id={"tooltipDuplicate" + pwaData.id}
                            onClick={() => showDuplicateModal(pwaData.id)}
                        >
                            <DuplicateIcon />
                        </button>
                    )}
                    <Link
                        to={`/stats/?id=${pwaData.id}`}
                        data-tooltip-content="Статистика"
                        data-tooltip-id={"tooltip" + pwaData.id}
                    >
                        <StatsIcon />
                    </Link>
                </div>
            </div>
            <ReactTooltip
                id={"tooltip" + pwaData.id}
                type="light"
                effect="solid"
            />
            <ReactTooltip
                id={"tooltipDuplicate" + pwaData.id}
                type="light"
                effect="solid"
            />
            <ReactTooltip
                id={"tooltipEdit" + pwaData.id}
                type="light"
                effect="solid"
            />
        </div>
    );
};

export default PWAItem;
